<template>
    <!-- Start Footer -->
    <footer class="py-10 text-gray-300 bg-gray-900">
        <div class="container mx-auto">
            <div class="grid grid-cols-12 gap-8">
                <!-- Logo and Description -->
                <div class="col-span-12 md:col-span-4">
                    <router-link to="#" class="logo-footer">
                        <img :src="logo" class="mb-4 w-36" alt="Logo" />
                    </router-link>
                    <p class="text-sm leading-relaxed">
                        Get your sound heard across the globe. Simplify your distribution
                        process and connect with millions of listeners on every major
                        streaming service. Let your talent shine, starting today.
                    </p>
                </div>

                <!-- Links Section -->
                <div class="col-span-12 md:col-span-8 text-start items-start">
                    <div class="grid grid-cols-3 gap-8">
                        <div>
                            <h6 class="mb-4 text-lg font-semibold">Product</h6>
                            <ul class="space-y-2 text-sm">
                                <li><router-link to="#" class="hover:text-white">Trending</router-link></li>
                                <li><router-link to="#" class="hover:text-white">Popular</router-link></li>
                                <li><router-link to="#" class="hover:text-white">Customers</router-link></li>
                                <li><router-link to="#" class="hover:text-white">Features</router-link></li>
                            </ul>
                        </div>
                        <div class="">
                            <h6 class="mb-4 text-lg font-semibold">Services</h6>
                            <ul class="space-y-2 text-sm  ">
                                <li><router-link to="/music-distribution" class="hover:text-white"> -Music
                                        Distribution</router-link></li>
                                <li>
                                    <router-link to="/video-distribution" class="hover:text-white">Video
                                        Distribution</router-link>
                                </li>
                                <li>
                                    <router-link to="/rights" class="hover:text-white">Rights Management</router-link>
                                </li>
                                <li class="">
                                    <router-link to="/monetization" class="hover:text-white">YouTube
                                        Monetization</router-link>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <h6 class="mb-4 text-lg font-semibold">Support</h6>
                            <ul class="space-y-2 text-sm">
                                <li><router-link to="#" class="hover:text-white">FAQ</router-link></li>
                                <li><router-link to="#" class="hover:text-white">Contact</router-link></li>
                                <li><router-link to="#" class="hover:text-white">Discussion</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer Bottom -->
            <div class="mt-8 md:col-span-4 md:mt-0">
                <ul class="text-center list-none foot-icon ltr:md:text-right rtl:md:text-left">
                    <li class="inline"><a href="https://1.envato.market/appever" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="shopping-cart" class="w-4 h-4 align-middle" title="Buy Now"></i></a></li>
                    <li class="inline"><a href="https://dribbble.com/shreethemes" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="dribbble" class="w-4 h-4 align-middle" title="dribbble"></i></a></li>
                    <li class="inline"><a href="http://linkedin.com/company/shreethemes" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="linkedin" class="w-4 h-4 align-middle" title="Linkedin"></i></a></li>
                    <li class="inline"><a href="https://www.facebook.com/shreethemes" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="facebook" class="w-4 h-4 align-middle" title="facebook"></i></a></li>
                    <li class="inline"><a href="https://www.instagram.com/shreethemes/" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="instagram" class="w-4 h-4 align-middle" title="instagram"></i></a></li>
                    <li class="inline"><a href="https://twitter.com/shreethemes" target="_blank"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="twitter" class="w-4 h-4 align-middle" title="twitter"></i></a></li>
                    <li class="inline"><a href="mailto:support@shreethemes.in"
                            class="inline-flex items-center justify-center text-base tracking-wide align-middle border border-gray-700 rounded-md size-8 hover:border-red-500 text-slate-300 hover:text-white hover:bg-red-500"><i
                                data-feather="mail" class="w-4 h-4 align-middle" title="email"></i></a></li>
                </ul><!--end icon-->
            </div><!--end col-->

            <div class="pt-6 mt-8 text-sm text-center border-t border-gray-700">
                © 2025 PlayTuneMusic Pvt LTD <p class="text-slate-400 text-[12px]"> developed by <a
                        href="https://russell.ruxzom.com" target="_blank"
                        class="text-red-500 hover:underline">RuxzomSoftware</a></p>
            </div>
        </div>
    </footer>
    <!-- End Footer -->
</template>

<script setup>
import { ref } from "vue";
import logo from "@/assets/images/logo-light.png";

const year = ref(new Date().getFullYear());
</script>
