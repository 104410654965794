<template>
    <div>

        <Navbar :playBtn="false" :bg="true" />

        <!-- Start Hero -->
        <section class="relative table w-full py-24 overflow-hidden">
            <div class="container relative">
                <div class="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div class="md:col-span-6">
                        <div class="md:me-6">
                            <!-- <h6 class="mb-3 text-sm font-bold tracking-wider">#No1 Trending Apps On Play Store</h6> -->
                            <h4 class="font-extrabold lg:leading-normal leading-normal text-4xl lg:text-[54px] mb-5 text-transparent bg-clip-text animate-gradient"
                                style="background-image: linear-gradient(90deg, #FF5733, #001EFFFF);"> {{
                                    homePageData[0] != null ? homePageData[0]?.title : 'Stream Your Dream Everywhere'
                                }}</h4>

                            <p class="max-w-xl mx-auto text-lg text-slate-400">{{ homePageData[0] != null ?
                                homePageData[0]?.description :
                                `Get your sound heard across the globel. Simplify your
                                distribution process and connect with millions of listeners on every major streaming
                                service. Let your talent shine, starting today` }} </p>

                            <div class="mt-6 mb-3 subcribe-form">

                                <a :href="homePageData[0]?.button_page_route" target="_blank">
                                    <Button type="submit" size="sm"
                                        class="h-11 px-5   text-[14px] tracking-wider inline-flex items-center justify-center font-medium rounded-2xl bg-red-500 text-white uppercase">
                                        {{ homePageData[0] != null ? homePageData[0]?.button_text : 'Start Free' }}
                                    </Button>
                                </a>
                            </div>


                        </div>
                    </div><!--end col-->

                    <div class="md:col-span-6 ">
                        <div
                            class="relative after:content-[''] after:absolute after:md:bottom-48 after:-bottom-20 lg:after:-start-10 md:after:-start-20 after:-start-24
                        after:bg-red-500 after:shadow-2xl after:shadow-red-500/40 after:z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[45rem] after:h-[30rem] after:rounded-[30rem]">
                            <img :src="homePageData[0] != null ? `${homePageData[0]?.image}` : `${phone}`"
                                class="lg:max-w-[600px]   md:max-w-md relative z-2 mover" alt="">
                        </div>
                    </div><!--end col-->
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Hero -->

        <!-- Business Partner -->
        <section class="py-6 border-t border-b border-gray-100 dark:border-gray-800">
            <Partner />
        </section><!--end section-->
        <!-- Business Partner -->
        <!-- <Extra_bn_right />

        <disturabtionarea />
        <Banner_title
            title="Get your sound heard across the globel. Get your sound heard across the globel.Get your sound heard across the globel."
            description="Get your sound heard across the globel. Simplify your distribution process and connect with millions of listeners on every major streaming service. Let your talent shine, starting today.">
        </Banner_title>
        <Plandetails />
        <ExtraFeature_right />
        <ExtraFeature_left /> -->
        <!-- Start -->
        <section class="relative py-16 md:py-24">
            <FetcherOne />
            <FeatureTwo />
        </section><!--end section-->


        <div class=" mt-16 md:mt-24">
            <Extra_bn_right />
        </div>

        <div class=" mt-16 md:mt-24">
            <ExtraFeature_left />
        </div>

        <!-- End -->
        <!-- Start -->
        <section class="relative py-16 md:py-24 bg-slate-50/50 dark:bg-slate-800/20">
            <Pricing />
        </section><!--end section-->
        <!-- End -->
        <!-- Start -->
        <section class="relative py-16 md:py-24 bg-slate-50/50 dark:bg-slate-800/20">
            <Promoshionfeture />
            <Download />
        </section><!--end section-->
        <!-- End -->

        <!-- id="reviews" -->
        <!-- Start -->
        <section class="relative py-16 md:py-24">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-6 text-center">
                    <h6 class="mb-3 text-sm font-bold tracking-wider text-red-500 uppercase">Reviews</h6>
                    <h4 class="mb-6 text-2xl font-bold leading-normal md:text-3xl md:leading-normal">10k+ Customers
                        Trust Us</h4>

                    <p class="max-w-xl mx-auto text-slate-400">Unleash the power of our platform with a multitude of
                        powerful features, empowering you to achieve your goals.</p>
                </div><!--end grid-->

                <Client />
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Section-->
        <!-- Start -->

        <!-- id="faqs" -->
        <section class="relative py-16 overflow-hidden md:py-24">
            <Faq />
        </section><!--end section-->
        <!-- End Section-->
        <!-- Start -->
        <section class="relative py-16 md:py-24 bg-slate-50/50 dark:bg-slate-800/20">
            <Contact />
        </section><!--end section-->
        <!-- End -->

        <FooterOne />
        <ScrollToTop />

        <Switcher />

    </div>


</template>
<style scoped>
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.animate-gradient {
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
}
</style>
<script setup>

import phone from '@/assets/images/phone/lapop_homepahe3.png'
import Button from '@/components/button.vue';
import Client from '@/components/client.vue';
import Contact from '@/components/contact.vue';
import Download from '@/components/download.vue';
import Faq from '@/components/faq.vue';
import FeatureTwo from '@/components/feature-two.vue';
import FetcherOne from '@/components/feature-one.vue';
import FooterOne from '@/components/footer-one.vue';
import Navbar from '@/components/navbar.vue';
import Partner from '@/components/partner.vue';
import Promoshionfeture from '@/components/promoshionfeture.vue';
import ScrollToTop from '@/components/scroll-to-top.vue';
import Switcher from '@/components/switcher.vue';
import Pricing from '@/components/pricing.vue';
import ExtraFeature_left from '@/components/someExtra_bn_left.vue';
import Extra_bn_right from '@/components/someExtra_bn_rbn.vue';



// State data Face

import { computed, ref, onMounted } from 'vue';
import { useBannerPageStore } from '@/stores/bannerPageStore';
import { useGlobalStore } from '@/stores/globalStore';
const globalStore = useGlobalStore();

// Access the Pinia store
const bannerPageStore = useBannerPageStore();

// Fetch data when the component is mounted
onMounted(async () => {
    try {
        globalStore.setLoading(true); // Start loading
        await bannerPageStore.fetchBannerPagesData();
    } finally {

        globalStore.setLoading(false); // Stop loading
    }
});

// Reactive data from the store
const bannerPagesData = computed(() => bannerPageStore.bannerPagesData);

// Filter data for the "home" page
const homePageData = computed(() => {
    return bannerPagesData.value.filter(page => page.page_name === "home");
});


</script>
