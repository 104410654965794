<template>
    <div class="container relative">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div class="relative order-1 md:order-2">
                <div class="relative">
                    <img :src="faqImg" class="mx-auto md:max-w-xs lg:max-w-sm" alt="">

                    <div class="absolute top-24 md:end-14 -end-2 text-center">
                        <router-link to="" @click="open = !open"
                            class="lightbox size-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center text-white bg-red-600">
                            <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                        </router-link>
                    </div>
                </div>
                <div
                    class="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-blue-500/20 via-blue-500/70 from-blue-500 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-blue-500/10 rounded-full">
                </div>


            </div>

            <div class="lg:me-8 order-2 md:order-1">
                <h6 class="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">FAQs</h6>
                <h4 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Have Questions? Look
                    Here</h4>
                <p class="text-slate-400 max-w-xl mx-auto">Explore common questions about PlayTuneMusic</p>

                <div class="mt-8">
                    <div v-for="(item, index) in faqdata" :key="index"
                        class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden"
                        :class="item.id != 1 ? 'mt-4' : ''">
                        <h2 class="font-semibold" id="accordion-collapse-heading-1">
                            <button type="button"
                                class="flex justify-between items-center p-5 w-full font-medium text-start"
                                :class="activeTab === item.id ? 'bg-slate-50/50 dark:bg-slate-800/20 text-red-500' : ''"
                                @click="faqStorein.setActiveTab(item.id)">
                                <span>{{ item.title }}</span>
                                <svg data-accordion-icon class="size-4 shrink-0"
                                    :class="activeTab === item.id ? ' rotate-180' : ''" fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </h2>
                        <div :class="activeTab === item.id ? '' : 'hidden'">
                            <div class="p-5">
                                <p class="text-slate-400 dark:text-gray-400">{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6">
                    <router-link to=""
                        class="hover:text-red-500 dark:hover:text-red-500 after:bg-red-500 dark:text-white transition duration-500 font-medium">Find
                        Out More <i class="mdi mdi-arrow-right"></i></router-link>
                </div>
            </div>
        </div><!--end grid-->

        <div class="fixed w-full h-[100vh] top-0 left-0 z-999" :class="open ? '' : 'hidden'"
            style="background-color: rgba(0, 0, 0, 0.5);">
            <div class="w-full h-full flex justify-center items-center">
                <div class="relative px-4 w-full max-w-2xl h-auto">
                    <button class="absolute right-0 -top-4 bg-slate-600 p-[2px] rounded z-[9999]"
                        @click="open = !open"><i data-feather="x" class="text-slate-300"></i></button>
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div class="flex justify-between items-start p-0">
                            <iframe width="750" height="400"
                                src="https://www.youtube.com/embed/yba7hPeTSjk?playlist=yba7hPeTSjk&loop=1"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div><!--end container-->
</template>

<script setup>
import { onMounted, ref } from 'vue';

import * as feather from 'feather-icons'
import CountUp from 'vue-countup-v3'
import { computed } from 'vue';

import faqImg from '@/assets/images/Question.png'

onMounted(() => {
    feather.replace()
})

const open = ref(false)

// const faqData = ref([
//     {
//         id: 1,
//         title: 'What is music distribution, and why is it important for independent artists?',
//         desc: 'Music distribution involves getting your music onto various platforms and streaming services so it can be accessed worldwide. It ensures your music reaches a broader audience and maximizes exposure.'
//     },
//     {
//         id: 2,
//         title: 'How does PlayTuneMusic Music’s distribution service work?',
//         desc: 'PlayTuneMusic Music simplifies the process of uploading and distributing your music across major streaming platforms.'
//     },
//     {
//         id: 3,
//         title: 'How can I increase my music’s visibility with PlayTuneMusic Music?',
//         desc: 'By leveraging PlayTuneMusic Music’s tools, you can optimize your music’s reach through promotional campaigns, analytics, and audience engagement.'
//     },
//     {
//         id: 4,
//         title: 'How do I sign up for PlayTuneMusic Music’s distribution services?',
//         desc: 'Signing up is easy! Simply visit our website, create an account, and upload your music to get started.'
//     },

// ])




import { faqStore } from '@/stores/faqStore';
import { useGlobalStore } from '@/stores/globalStore';
const globalStore = useGlobalStore();
// Access the Pinia store
const faqStorein = faqStore();

// Fetch data when the component is mounted
onMounted(async () => {
    try {
        globalStore.setLoading(true); // Start loading
        await faqStorein.fetchfaqData();
    } finally {

        globalStore.setLoading(false); // Stop loading
    }
});
const activeTab = computed(() => faqStorein.activeTab);
// Reactive data from the store
const faqdata = computed(() => faqStorein.faqData);
console.log("faqdata");
console.log(faqdata.value);



</script>
