<template>
    <div class="min-h-screen bg-gray-100 dark:bg-gray-900 py-12">
        <div class="max-w-6xl mx-auto py-10 px-4 sm:px-6 lg:px-8 ">

            <!-- Pricing Table -->
            <div class="overflow-hidden rounded-lg  neumorphism-effect bg-gray-100 dark:bg-gray-900  ">
                <table class="table-auto w-full border-collapse">
                    <thead>
                        <tr class="bg-gray-700 ">
                            <th class="text-left p-10 text-4xl  uppercase font-extrabold text-transparent bg-clip-text leading-normal animate-gradient"
                                style="background-image: linear-gradient(90deg, #FF5733, #FADB10FF);">Features</th>
                            <th v-for="plan in pricedata" :key="plan.title"
                                class=" p-4 text-transparent bg-clip-text leading-normal animate-gradient"
                                style="background-image: linear-gradient(90deg, #FF5733, #FADB10FF);">
                                <p class="  text-xl uppercase font-extrabold "> {{ plan.title
                                    }}</p>
                                <p class="text-2xl text-gray-200 "> $
                                    {{ parseInt(plan.yearly_price).toString() }}
                                </p>
                                <p class="text-[12px]  text-gray-200">
                                    yearly</p>

                                <Button size="sm" class=" text-gray-700 mt-3 dark:text-gray-200">Start Now</Button>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(feature, index) in priceFeaturesdata" :key="index"
                            class="border-b border-gray-300 dark:border-gray-700">
                            <td class="p-4 text-gray-700 dark:text-gray-300">{{ feature.title }}</td>
                            <td v-for="(plan, planIndex) in pricedata" :key="planIndex" class="text-center p-4">
                                <p>{{ featureFilter(plan.features, feature.id) ? '✅' : '❌' }}</p>
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-400 mx-auto"
                                    viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                        clip-rule="evenodd" />

                                </svg> -->

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Button from './button.vue';



const plans = ref([
    {
        name: "Professional",
        price: "$29.99",
        time: "yearly",
        features: ["green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green", "green"],
    },
    {
        name: "Breakout Artist",
        price: "$59.99",
        time: "yearly",
        features: ["green", "green", "green", "green", "red", "red", "red", "green", "green", "green", "green", "green", "green", "red", "red", "red"],
    },
    {
        name: "Rising Artist",
        price: "$99.99",
        time: "yearly",
        features: ["green", "green", "red", "green", "red", "red", "red", "green", "green", "red", "red", "red", "red", "red", "red", "red"],
    },
    {
        name: "New Artist",
        price: "Free",
        time: "yearly",
        features: ["red", "red", "red", "red", "red", "red", "red", "blue", "blue", "red", "red", "red", "blue", "red", "red", "red"],
    },




]);



import { onMounted, computed } from 'vue';
import { priceStore } from '@/stores/priceStore';
import { useGlobalStore } from '@/stores/globalStore';
const globalStore = useGlobalStore();
// Access the Pinia store
const priceInSt = priceStore();

// Fetch data when the component is mounted
onMounted(async () => {
    try {
        globalStore.setLoading(true); // Start 
        await priceInSt.fetchPriceFeaturesData();
        await priceInSt.fetchPricePlanData();
    } finally {

        globalStore.setLoading(false); // Stop loading
    }
});


function featureFilter(rx, item) {

    let data = rx.filter(fx => fx.id === item);
    console.log("data");
    console.log(data);
    return data.length >= 1 ? true : false;


}


// Reactive data from the store
const pricedata = computed(() => priceInSt.PricePlanData.filter(card => card.plan_type === "table_plan"));
const priceFeaturesdata = computed(() => priceInSt.PriceFeaturesData);
const isYearly = computed(() => priceInSt.isYearly);
console.log("privcPlanData");
console.log(pricedata.value);




</script>

<style>
.neumorphism-effect {
    border-radius: 0.75rem;
    /* Rounded corners */
    background: #0f172a;
    /* Background color */
    box-shadow: 0px 0px 25px 25px rgba(255, 255, 255, 0.3),
        0px 0px 12px 12px #85ff508b;
    /* Initial shadow */
    animation: neumorphism-gradient 4s ease-in-out infinite;
    /* Add animation */
}

/* Keyframes for animated gradient shadow */
@keyframes neumorphism-gradient {
    0% {
        box-shadow: 0px 0px 25px 25px rgba(255, 255, 255, 0.3),
            0px 0px 12px 12px rgba(133, 255, 80, 0.65);
    }

    33% {
        box-shadow: 0px 0px 30px 30px rgba(255, 100, 100, 0.4),
            0px 0px 15px 15px rgba(255, 150, 50, 0.5);
    }

    66% {
        box-shadow: 0px 0px 28px 28px rgba(100, 200, 255, 0.5),
            0px 0px 13px 13px rgba(80, 255, 200, 0.7);
    }

    100% {
        box-shadow: 0px 0px 25px 25px rgba(255, 255, 255, 0.3),
            0px 0px 12px 12px rgba(133, 255, 80, 0.65);
    }


}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.animate-gradient {
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
}
</style>