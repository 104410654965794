<template>
        <div class="container relative">
            <div class="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                <div v-for="(item, index) in images" :key="index" class="py-4 mx-auto">
                    <img :src="item" class="h-6" alt="">
                </div>
            </div><!--end grid-->
        </div><!--end container-->
</template>

<script setup>

    import { ref } from 'vue'
    import partner1 from '@/assets/partner/1707246729-apple-logo-white.svg'
    import partner2 from '@/assets/partner/1707246819-tiktok-logo-white.svg'
    import partner3 from '@/assets/partner/1707246830-youtube-logo-white.svg'
    import partner4 from '@/assets/partner/1707247183-deezer-logo-white.svg'
    import partner5 from '@/assets/partner/spotify.svg'
    import partner6 from '@/assets/partner/1707246838-beatport-logo-white.svg'

    const images = ref([
        partner1,partner2,partner3,partner4,partner5,partner6
    ])

</script>
