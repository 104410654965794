<template>
    <div>
 <!-- Switcher -->
        <div class="fixed z-50 top-1/4 -right-3">
            <span class="relative inline-block rotate-90">
                <input type="checkbox" class="absolute opacity-0 checkbox" id="chk" @change="changeMode($event)"/>
                <label class="flex items-center justify-between h-8 p-1 rounded-full shadow cursor-pointer label bg-slate-900 dark:bg-white dark:shadow-gray-800 w-14" for="chk" >
                    <i data-feather="moon" class="text-yellow-500 size-4"></i>
                    <i data-feather="sun" class="text-yellow-500 size-4"></i>
                    <span class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"></span>
                </label>
            </span>
        </div>
        <!-- Switcher -->

        <!-- LTR & RTL Mode Code -->
        <!-- <div class="fixed z-50 top-1/3 -right-3">
            <a href="" id="switchRtl">
                <span @click="changeThem($event)" class="relative inline-block px-3 py-1 font-semibold -rotate-90 bg-white shadow-md rounded-t-md dark:bg-slate-900 dark:shadow dark:shadow-gray-800 rtl:block ltr:hidden" >LTR</span>
                <span @click="changeThem($event)" class="relative inline-block px-3 py-1 font-semibold -rotate-90 bg-white shadow-md rounded-t-md dark:bg-slate-900 dark:shadow dark:shadow-gray-800 ltr:block rtl:hidden">RTL</span>
            </a>
        </div> -->
        <!-- LTR & RTL Mode Code -->
    </div>
</template>

<script setup>
    import * as feather from 'feather-icons'
    import { onMounted, ref } from 'vue';

    const htmlTag = ref(document.getElementsByTagName("html")[0])
    onMounted(()=>{
        feather.replace()
        console.log(htmlTag.value.className.includes("light"));
        
    })


    const changeMode = (event) =>{
        event.preventDefault();
        if (htmlTag.value.className.includes("dark")) {
            htmlTag.value.className = 'light'
        } else {
            htmlTag.value.className = 'dark'
        }
    }

    const changeThem =(event) =>{
        event.preventDefault();
        
        if (event.target.innerText === "LTR") {
            htmlTag.value.dir = "ltr"
        }
        else {
            htmlTag.value.dir = "rtl"
        }
    }

</script>