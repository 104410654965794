<template>
    <div>
        <!-- Loading Overlay -->
        <div v-if="isLoading" class="fixed  inset-0 flex items-center justify-center z-999 bg-gradient ">
            <!-- Blurry Gradient Background -->
            <div class="absolute inset-0 blur-overlay"></div>

            <!-- Loading Logo -->
            <img :src="logo" alt="Loading Logo" class="animate-zoom-in-out w-16 h-16" />
        </div>
    </div>
</template>

<script setup>
import logo from '@/assets/logo-icon.png'
import { useGlobalStore } from '@/stores/globalStore';
import { computed } from 'vue';

// Access the global store
const globalStore = useGlobalStore();
const isLoading = computed(() => globalStore.isLoading);

</script>

<style>
/* Keyframe Animation for Zoom-In-Out Effect */
@keyframes zoom-in-out {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

.animate-zoom-in-out {
    animation: zoom-in-out 1.5s ease-in-out infinite;
}

/* Gradient Background Overlay */
.bg-gradient {
    position: relative;
    background: linear-gradient(135deg, rgba(94, 204, 255, 0.814), #FA846AFF);

}

/* Blurry Effect Layer */
.blur-overlay {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    position: absolute;
    inset: 0;
    z-index: -1;
}
</style>