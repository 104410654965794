import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import { createPinia } from "pinia";
import "animate.css"; // Import animate.css
// import WOW from 'wowjs'; // Import Wow.js
const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(router);
app.mount("#app");
