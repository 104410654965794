<template>
    <!-- Navbar Start -->
    <nav class="navbar"
        :class="[scroll ? 'is-sticky' : '', bg ? 'bg-white dark:bg-slate-900 shadow dark:shadow-gray-800' : '']"
        id="navbar">
        <div class="container relative flex flex-wrap items-center justify-between">
            <router-link v-if="!navLight" class="navbar-brand md:me-8" to="/">
                <img :src="logoDark" class="inline-block h-8 dark:hidden" alt="" />
                <img :src="logoLight" class="hidden h-8 dark:inline-block" alt="" />
            </router-link>

            <router-link v-if="navLight" class="navbar-brand" to="/">
                <span class="inline-block dark:hidden">
                    <img :src="logoDark" class="l-dark" alt="" />
                    <img :src="logoLight" class="l-light" alt="" />
                </span>
                <img :src="logoLight" class="hidden dark:inline-block" alt="" />
            </router-link>

            <div class="flex items-center nav-icons lg_992:order-2 md:ms-6">
                <!-- Navbar Button -->
                <ul class="mb-0 list-none menu-social">
                    <li class="inline">
                        <router-link to="/signup">
                            <Button size="sm"
                                class="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-full bg-red-500 text-white uppercase">
                                Signup
                            </Button>
                        </router-link>
                    </li>
                </ul>
                <!-- Navbar Collapse Menu Button -->
                <button type="button"
                    class="inline-flex items-center collapse-btn ms-2 text-slate-900 dark:text-white lg_992:hidden"
                    v-on:click="handler()">
                    <span class="sr-only">Navigation Menu</span>
                    <i class="mdi mdi-menu text-[24px]"></i>
                </button>
            </div>

            <!-- Navbar Menu -->
            <div class="navigation lg_992:order-1 lg_992:flex" id="menu-collapse"
                :class="[toggle ? '' : 'hidden', navCenter ? '' : 'ms-auto']">
                <ul class="navbar-nav" :class="navLight ? 'nav-light' : ''" id="navbar-navlist">
                    <li v-for="menuItem in menuItems" :key="menuItem.id" class="nav-item"
                        :class="{ active: currentRoute === menuItem.path }">
                        <router-link class="nav-link" :to="menuItem.path">
                            {{ menuItem.label }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Navbar End -->
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import feather from 'feather-icons';
import Button from '@/components/button.vue';
import { useRoute } from 'vue-router';

import logoDark from '@/assets/images/logo-dark.png';
import logoLight from '@/assets/images/logo-light.png';

defineProps({
    navLight: Boolean,
    playBtn: Boolean,
    bg: Boolean,
    navCenter: Boolean
});

const toggle = ref(false);
const scroll = ref(false);
const currentRoute = ref('');
const route = useRoute();

const menuItems = [
    { id: 'home', label: 'Home', path: '/' },
    { id: 'service', label: 'Service', path: '/service' },
    { id: 'pricing', label: 'Pricing', path: '/pricing' },
    { id: 'promotion', label: 'Promotion', path: '/promotion' },

];

onMounted(() => {
    currentRoute.value = route.path;

    window.addEventListener('scroll', handleScroll);
    feather.replace();
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

const handleScroll = () => {
    // Update sticky navbar state
    scroll.value = window.scrollY >= 50;
};

const handler = () => {
    toggle.value = !toggle.value;
};
</script>

<style scoped>
.nav-item.active .nav-link {
    color: #e63946;
    /* Active color */
    font-weight: bold;
    /* Optional for emphasis */
}
</style>
