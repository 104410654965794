<template>
    <div class="container relative">
        <div class="grid grid-cols-1 pb-6 text-center">
            <h6 class="mb-3 text-sm font-bold tracking-wider text-red-500 uppercase">DISTRIBUTION</h6>
            <h4 class="mb-6 text-2xl font-bold leading-normal md:text-3xl md:leading-normal">Experience Next-Level
                Distribution
            </h4>

            <p class="max-w-xl mx-auto text-slate-400">Finalizing a distribution deal for your music will allow you to
                begin showcasing your work globally and attract new listeners </p>
        </div><!--end grid-->

        <div class="grid items-center grid-cols-1 gap-6 mt-6 lg:grid-cols-12 md:grid-cols-12">
            <div class="order-2 lg:col-span-4 md:col-span-6 lg:order-1">
                <div class="grid grid-cols-1 gap-6">
                    <div v-for="(item, index) in feature1" :key="index" class="flex duration-500 group xl:p-3">
                        <div
                            class="flex items-center justify-center order-1 mt-1 text-2xl text-red-500 align-middle duration-500 shadow-sm md:order-2 size-16 bg-red-500/5 dark:bg-red-500/10 group-hover:bg-red-500 group-hover:text-white rounded-2xl dark:shadow-gray-800">
                            <i :data-feather="item.icon" class="size-5"></i>
                        </div>
                        <div class="flex-1 order-2 md:order-1 md:text-end md:me-4 md:ms-0 ms-4">
                            <h4 class="mb-0 text-lg font-semibold">{{ item.title }}</h4>
                            <p class="mt-3 text-slate-400">{{ item.desc }}</p>
                        </div>
                    </div>
                    <!--end feature-->
                </div>
            </div>

            <div class="order-1 lg:col-span-4 md:col-span-12 lg:mx-4 lg:order-2">
                <img :src="phone" class="mx-auto" alt="">
            </div>

            <div class="order-3 lg:col-span-4 md:col-span-6">
                <div class="grid grid-cols-1 gap-6">
                    <div v-for="(item, index) in feature2" :key="index" class="flex duration-500 group xl:p-3">
                        <div
                            class="flex items-center justify-center mt-1 text-2xl text-red-500 align-middle duration-500 shadow-sm size-16 bg-red-500/5 dark:bg-red-500/10 group-hover:bg-red-500 group-hover:text-white rounded-2xl dark:shadow-gray-800">
                            <i :data-feather="item.icon" class="size-5"></i>
                        </div>
                        <div class="flex-1 ms-4">
                            <h4 class="mb-0 text-lg font-semibold">{{ item.title }}</h4>
                            <p class="mt-3 text-slate-400">{{ item.desc }}</p>
                        </div>
                    </div>
                    <!--end feature-->
                </div>
            </div>
          
        </div><!--end grid-->
    </div><!--end container-->
</template>

<script setup>

import phone from '@/assets/images/phone/11.png'
import { ref } from 'vue';



const feature1 = ref([
    {
        icon: 'user-plus',
        title: 'Register ',
        desc: 'Register on our platform and prepare your materials following all provided instructions.'
    },
    {
        icon: 'upload-cloud',
        title: 'Upload',
        desc: 'Upload your tracks and choose the platforms for distribution on your needs.'
    },

])
const feature2 = ref([
    {
        icon: 'activity',
        title: 'Monitor streaming',
        desc: 'Monitor streaming statistics and receive detailed reports.'
    },
    {
        icon: 'briefcase',
        title: 'Financial',
        desc: 'Manage your financial transactions and receive fair and timely payouts!'
    },

])

</script>
