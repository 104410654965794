import { defineStore } from "pinia";

export interface Faq {
  id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export const faqStore = defineStore("faq", {
  state: () => ({
    faqData: [] as Faq[],
    activeTab: 1,
  }),
  actions: {

    setActiveTab(tabId: number) {
      this.activeTab = tabId;
    },

    async fetchfaqData() {
      console.log(process.env.VUE_APP_API_URL);
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/faqs");
        const data = await response.json();
        // console.log(data);
        this.faqData = data.data;
        this.activeTab = data.data[0].id;
      } catch (error) {
        console.error("Error fetching faq data:", error);
      }
    },
  },
});
