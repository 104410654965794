<template>
    <button :class="[
        'rounded-2xl font-bold text-white transition-all ease-in-out hover:shadow-lg',
        hoverShadowClass,
        gradientClass,
        sizeClass
    ]" @click="handleClick">
        <slot />
    </button>
</template>

<script>
export default {
    name: "GradientButton",
    props: {
        size: {
            type: String,
            default: "md", // Options: 'sm', 'md', 'lg'
        },
        gradient: {
            type: String,
            default: "bg-gradient-to-r from-pink-500 via-red-500 to-indigo-500", // Default gradient
        },
        hoverShadow: {
            type: String,
            default: "hover:shadow-purple-500/50", // Default hover shadow color
        },
    },
    computed: {
        sizeClass() {
            switch (this.size) {
                case "sm":
                    return "px-4 py-2 text-sm";
                case "lg":
                    return "px-8 py-4 text-lg";
                default:
                    return "px-6 py-3 text-base";
            }
        },
        gradientClass() {
            return `${this.gradient} animate-gradient`; // Dynamically applies gradient prop
        },
        hoverShadowClass() {
            return this.hoverShadow; // Dynamically applies hover shadow color
        },
    },
    methods: {
        handleClick(event) {
            this.$emit("click", event); // Emits the click event
        },
    },
};
</script>

<style>
/* Keyframes for gradient animation */
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Gradient animation class */
.animate-gradient {
    background-size: 200% 200%;
    animation: gradientMove 3s ease infinite;
}
</style>